class FormStepperValidate {
  constructor(inputWrap) {
    this.errorMessage = {};
    this.errorMessageWrap = inputWrap.querySelector('.error-message');
    this.inputs = inputWrap.querySelectorAll('input:not([type="button"]):not([type="submit"]), select, textarea');
  }

  validateInput() {
    let overallValid = true;
    this.errorMessage = {};

    this.inputs.forEach(function (input) {
      let valid = true;
      input.classList.remove('error');

      // skip this if specifically set optional or just no required class
      if (input.classList.contains('optional') || !input.classList.contains('field-required')) {
        return true;
      }

      if (input.value === '') {
        overallValid = valid = false;
      }

      const inputId = input.getAttribute('id');

      if (inputId && inputId.match(/email/)
        && !this.validateEmail(input.value)) {
        overallValid = valid = false;
      }

      const fieldType = input.getAttribute('type');

      // if checkbox and checkbox not checked
      if (fieldType && fieldType === 'checkbox' && !input.checked) {
        overallValid = valid = false;
      }

      if (!valid) {
        input.classList.add('error');
      }

    }, this);

    return overallValid;
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}